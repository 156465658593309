:where(.vi__wrapper) {
    position: relative;
    width: min-content;
    margin: auto;
    margin-top: 15px;
  }

  .vi {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    position: absolute;
    color: transparent;
    background: transparent;
    caret-color: transparent;
    outline: none;
    border: 0 none transparent;
  }

  .vi::selection {
    background: transparent;
  }

  :where(.vi__container) {
    display: flex;
    gap: 20px;
    height: 50px;
    width: 350px;
  }

  :where(.vi__character) {
    height: 100%;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    font-size: 36px;
    line-height: 50px;
    color: black;
    background-color: white;
    border-bottom: 1px solid  #E1E1E1;
    cursor: default;
    user-select: none;
    box-sizing: border-box;
  }

  :where(.vi__character--inactive) {
    border-bottom: 1px solid  #E1E1E1;

  }

  :where(.vi__character--selected) {
    border-bottom: 1px solid  #1CAD92;
  }